import * as React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { breakpoint } from '../breakpoints/breakpoints';
import Layout from '../components/layout';
import Flex from '../components/flex';
import Home from '../icons/Home';

const StyledFlex = styled(Flex)`
  background-color: ${(props) => props.bgColor};
  padding: 20px 40px;

  @media ${breakpoint.mobileXL} {
    padding: 20px 100px;
  }

  @media ${breakpoint.tablet} {
    padding: 20px 150px;
  }
  @media ${breakpoint.laptop} {
    padding: 20px 200px;
  }

  & h1 {
    color: ${(props) => props.color1};
  }
  & h2 {
    color: ${(props) => props.color2};
  }
`;

const StyledHome = styled(Home)`
  width: 30px;
  margin: 30px 0 30px -15px;
  fill: ${(props) => props.color1};
  cursor: pointer;

  & :hover {
    fill: ${(props) => props.color2};
  }
`;

const H2 = styled.h2`
  font-size: 25px;
  line-height: 120%;
  padding-top: 60px;
`;

const H3 = styled.h3`
  font-size: 20px;
  line-height: 120%;
  padding-top: 30px;
`;

const H4 = styled.h4`
  font-size: 17px;
  line-height: 181.18%;
  padding-top: 10px;
`;

function Datenschutz({ data }) {
  if (!data) return null;
  const pageData = data.allPrismicLanding.edges[0].node.data;
  const button = pageData.body.find(
    (slice) => slice.slice_type === 'button_on_hero'
  );

  return (
    <Layout>
      <StyledFlex
        column
        bgColor={pageData.bgcolor1}
        color1={pageData.captioncolor}
        color2={pageData.captioncolor}
      >
        <Link to="/">
          <StyledHome
            color1={button.items[0].button_color}
            color2={button.items[0].hover_color}
          />
        </Link>
        <h1>Datenschutz­erklärung</h1>
        <H2>1. Datenschutz auf einen Blick</H2>
        <H3>Allgemeine Hinweise</H3>
        <p>
          Die folgenden Hinweise geben einen einfachen Überblick darüber, was
          mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website
          besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
          persönlich identifiziert werden können. Ausführliche Informationen zum
          Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten
          Datenschutzerklärung.
        </p>
        <H3>Datenerfassung auf dieser Website</H3>
        <H4>
          Wer ist verantwortlich für die Datenerfassung auf dieser Website?
        </H4>
        <p>
          Die Datenverarbeitung auf dieser Website erfolgt durch den
          Websitebetreiber. Dessen Kontaktdaten können Sie dem Abschnitt
          „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung
          entnehmen.
        </p>
        <H3>Wie erfassen wir Ihre Daten?</H3>
        <p>
          Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
          mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in ein
          Kontaktformular eingeben.
        </p>{' '}
        <p>
          Andere Daten werden automatisch oder nach Ihrer Einwilligung beim
          Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem
          technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit
          des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch,
          sobald Sie diese Website betreten.
        </p>
        <H3>Wofür nutzen wir Ihre Daten?</H3>
        <p>
          Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
          der Website zu gewährleisten. Andere Daten können zur Analyse Ihres
          Nutzerverhaltens verwendet werden.
        </p>
        <H3>Welche Rechte haben Sie bezüglich Ihrer Daten?</H3>
        <p>
          Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft,
          Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
          erhalten. Sie haben außerdem ein Recht, die Berichtigung oder Löschung
          dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur
          Datenverarbeitung erteilt haben, können Sie diese Einwilligung
          jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht,
          unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
          Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
        </p>
        <p>
          Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich
          jederzeit an uns wenden.
        </p>
        <H3>Analyse-Tools und Tools von Dritt­anbietern</H3>
        <p>
          Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch
          ausgewertet werden. Das geschieht vor allem mit sogenannten
          Analyseprogrammen.
        </p>
        <p>
          Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in
          der folgenden Datenschutzerklärung.
        </p>
        <H2>2. Hosting</H2>
        <H3>Externes Hosting</H3>
        <p>
          Diese Website wird bei einem externen Dienstleister gehostet (Hoster).
          Die personenbezogenen Daten, die auf dieser Website erfasst werden,
          werden auf den Servern des Hosters gespeichert. Hierbei kann es sich
          v. a. um IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten,
          Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe und sonstige
          Daten, die über eine Website generiert werden, handeln.
        </p>
        <p>
          Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung
          gegenüber unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1
          lit. b DSGVO) und im Interesse einer sicheren, schnellen und
          effizienten Bereitstellung unseres Online-Angebots durch einen
          professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
        </p>{' '}
        <p>
          Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur
          Erfüllung seiner Leistungspflichten erforderlich ist und unsere
          Weisungen in Bezug auf diese Daten befolgen.
        </p>
        <p>Wir setzen folgenden Hoster ein:</p>
        <p>
          loswebos.de GmbH
          <br />
          Tschaikowskistr. 21
          <br />
          04105 Leipzig
        </p>
        <H4>Auftragsverarbeitung</H4>{' '}
        <p>
          Wir haben einen Vertrag über Auftragsverarbeitung (AVV) mit dem oben
          genannten Anbieter geschlossen. Hierbei handelt es sich um einen
          datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet, dass
          dieser die personenbezogenen Daten unserer Websitebesucher nur nach
          unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.
        </p>
        <H2>3. Allgemeine Hinweise und Pflicht­informationen</H2>
        <H3>Datenschutz</H3>
        <p>
          Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten
          sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
          entsprechend den gesetzlichen Datenschutzvorschriften sowie dieser
          Datenschutzerklärung.
        </p>
        <p>
          Wenn Sie diese Website benutzen, werden verschiedene personenbezogene
          Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie
          persönlich identifiziert werden können. Die vorliegende
          Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir
          sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das
          geschieht.
        </p>
        <p>
          Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B.
          bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann.
          Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
          nicht möglich.
        </p>
        <H3>Hinweis zur verantwortlichen Stelle</H3>
        <p>
          Die verantwortliche Stelle für die Datenverarbeitung auf dieser
          Website ist:
        </p>
        <p>
          Markus Lehnert
          <br />
          Solmsstraße 81
          <br />
          60486 Frankfurt
          <br />
          Deutschland
        </p>
        <p>
          Telefon: +49 (69) 2547 2722
          <br />
          E-Mail: markus.lehnert@spirituelle-hypnose.net
        </p>
        <p>
          Verantwortliche Stelle ist die natürliche oder juristische Person, die
          allein oder gemeinsam mit anderen über die Zwecke und Mittel der
          Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen
          o. Ä.) entscheidet.
        </p>
        <H3>Speicherdauer</H3>
        <p>
          Soweit innerhalb dieser Datenschutzerklärung keine speziellere
          Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten
          bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie
          ein berechtigtes Löschersuchen geltend machen oder eine Einwilligung
          zur Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern
          wir keine anderen rechtlich zulässigen Gründe für die Speicherung
          Ihrer personenbezogenen Daten haben (z. B. steuer- oder
          handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall
          erfolgt die Löschung nach Fortfall dieser Gründe.
        </p>
        <H3>
          Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten
        </H3>
        <p>
          Wir verwenden unter anderem Tools von Unternehmen mit Sitz in den USA
          oder sonstigen datenschutzrechtlich nicht sicheren Drittstaaten. Wenn
          diese Tools aktiv sind, können Ihre personenbezogene Daten in diese
          Drittstaaten übertragen und dort verarbeitet werden. Wir weisen darauf
          hin, dass in diesen Ländern kein mit der EU vergleichbares
          Datenschutzniveau garantiert werden kann. Beispielsweise sind
          US-Unternehmen dazu verpflichtet, personenbezogene Daten an
          Sicherheitsbehörden herauszugeben, ohne dass Sie als Betroffener
          hiergegen gerichtlich vorgehen könnten. Es kann daher nicht
          ausgeschlossen werden, dass US-Behörden (z. B. Geheimdienste) Ihre auf
          US-Servern befindlichen Daten zu Überwachungszwecken verarbeiten,
          auswerten und dauerhaft speichern. Wir haben auf diese
          Verarbeitungstätigkeiten keinen Einfluss.
        </p>
        <H3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</H3>
        <p>
          Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
          Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
          jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf
          erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
        </p>
        <H3>
          Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie
          gegen Direktwerbung (Art. 21 DSGVO)
        </H3>
        <p>
          WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER
          F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH
          AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER
          PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN
          AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING. DIE JEWEILIGE
          RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE
          DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR
          IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES
          SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE
          VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN
          ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG
          ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS.
          1 DSGVO).
        </p>
        <p>
          WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU
          BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE
          VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE
          DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING,
          SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE
          WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT
          MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21
          ABS. 2 DSGVO).
        </p>
        <H3>Beschwerde­recht bei der zuständigen Aufsichts­behörde</H3>
        <p>
          Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
          Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
          Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
          oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht
          besteht unbeschadet anderweitiger verwaltungsrechtlicher oder
          gerichtlicher Rechtsbehelfe.
        </p>
        <H3>Recht auf Daten­übertrag­barkeit</H3>
        <p>
          Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
          oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich
          oder an einen Dritten in einem gängigen, maschinenlesbaren Format
          aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an
          einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es
          technisch machbar ist.
        </p>
        <H3>SSL- bzw. TLS-Verschlüsselung</H3>
        <p>
          Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
          Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder
          Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw.
          TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie
          daran, dass die Adresszeile des Browsers von „http://“ auf „https://“
          wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
        </p>{' '}
        <p>
          Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die
          Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
          werden.
        </p>
        <H3>Auskunft, Löschung und Berichtigung</H3>
        <p>
          Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit
          das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
          personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck
          der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder
          Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema
          personenbezogene Daten können Sie sich jederzeit an uns wenden.
        </p>
        <H3>Recht auf Einschränkung der Verarbeitung</H3>
        <p>
          Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit
          an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in
          folgenden Fällen:
        </p>
        <ul>
          <li>
            Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
            personenbezogenen Daten bestreiten, benötigen wir in der Regel Zeit,
            um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das
            Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen
            Daten zu verlangen.
          </li>
          <li>
            Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
            geschah/geschieht, können Sie statt der Löschung die Einschränkung
            der Datenverarbeitung verlangen.
          </li>
          <li>
            Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie
            jedoch zur Ausübung, Verteidigung oder Geltendmachung von
            Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung
            die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
            verlangen.
          </li>
          <li>
            Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt
            haben, muss eine Abwägung zwischen Ihren und unseren Interessen
            vorgenommen werden. Solange noch nicht feststeht, wessen Interessen
            überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung
            Ihrer personenbezogenen Daten zu verlangen.
          </li>
        </ul>
        <p>
          Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt
          haben, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit
          Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung
          von Rechtsansprüchen oder zum Schutz der Rechte einer anderen
          natürlichen oder juristischen Person oder aus Gründen eines wichtigen
          öffentlichen Interesses der Europäischen Union oder eines
          Mitgliedstaats verarbeitet werden.
        </p>
        <H3>Widerspruch gegen Werbe-E-Mails</H3>
        <p>
          Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
          Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter
          Werbung und Informationsmaterialien wird hiermit widersprochen. Die
          Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im
          Falle der unverlangten Zusendung von Werbeinformationen, etwa durch
          Spam-E-Mails, vor.
        </p>
        <H2>4. Datenerfassung auf dieser Website</H2>
        <H3>Cookies</H3>
        <p>
          Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind
          kleine Textdateien und richten auf Ihrem Endgerät keinen Schaden an.
          Sie werden entweder vorübergehend für die Dauer einer Sitzung
          (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem
          Endgerät gespeichert. Session-Cookies werden nach Ende Ihres Besuchs
          automatisch gelöscht. Permanente Cookies bleiben auf Ihrem Endgerät
          gespeichert, bis Sie diese selbst löschen oder eine automatische
          Löschung durch Ihren Webbrowser erfolgt.
        </p>{' '}
        <p>
          Teilweise können auch Cookies von Drittunternehmen auf Ihrem Endgerät
          gespeichert werden, wenn Sie unsere Seite betreten
          (Third-Party-Cookies). Diese ermöglichen uns oder Ihnen die Nutzung
          bestimmter Dienstleistungen des Drittunternehmens (z. B. Cookies zur
          Abwicklung von Zahlungsdienstleistungen).
        </p>{' '}
        <p>
          Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind
          technisch notwendig, da bestimmte Websitefunktionen ohne diese nicht
          funktionieren würden (z. B. die Warenkorbfunktion oder die Anzeige von
          Videos). Andere Cookies dienen dazu, das Nutzerverhalten auszuwerten
          oder Werbung anzuzeigen.
        </p>
        <p>
          Cookies, die zur Durchführung des elektronischen
          Kommunikationsvorgangs (notwendige Cookies) oder zur Bereitstellung
          bestimmter, von Ihnen erwünschter Funktionen (funktionale Cookies, z.
          B. für die Warenkorbfunktion) oder zur Optimierung der Website (z. B.
          Cookies zur Messung des Webpublikums) erforderlich sind, werden auf
          Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert, sofern keine
          andere Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein
          berechtigtes Interesse an der Speicherung von Cookies zur technisch
          fehlerfreien und optimierten Bereitstellung seiner Dienste. Sofern
          eine Einwilligung zur Speicherung von Cookies abgefragt wurde, erfolgt
          die Speicherung der betreffenden Cookies ausschließlich auf Grundlage
          dieser Einwilligung (Art. 6 Abs. 1 lit. a DSGVO); die Einwilligung ist
          jederzeit widerrufbar.
        </p>
        <p>
          Sie können Ihren Browser so einstellen, dass Sie über das Setzen von
          Cookies informiert werden und Cookies nur im Einzelfall erlauben, die
          Annahme von Cookies für bestimmte Fälle oder generell ausschließen
          sowie das automatische Löschen der Cookies beim Schließen des Browsers
          aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität
          dieser Website eingeschränkt sein.
        </p>
        <p>
          Soweit Cookies von Drittunternehmen oder zu Analysezwecken eingesetzt
          werden, werden wir Sie hierüber im Rahmen dieser Datenschutzerklärung
          gesondert informieren und ggf. eine Einwilligung abfragen.
        </p>
        <H3>Einwilligung mit Borlabs Cookie</H3>
        <p>
          Unsere Website nutzt die Consent-Technologie von Borlabs Cookie, um
          Ihre Einwilligung zur Speicherung bestimmter Cookies in Ihrem Browser
          oder zum Einsatz bestimmter Technologien einzuholen und diese
          datenschutzkonform zu dokumentieren. Anbieter dieser Technologie ist
          Borlabs - Benjamin A. Bornschein, Rübenkamp 32, 22305 Hamburg (im
          Folgenden Borlabs).
        </p>
        <p>
          Wenn Sie unsere Website betreten, wird ein Borlabs-Cookie in Ihrem
          Browser gespeichert, in dem die von Ihnen erteilten Einwilligungen
          oder der Widerruf dieser Einwilligungen gespeichert werden. Diese
          Daten werden nicht an den Anbieter von Borlabs Cookie weitergegeben.
        </p>
        <p>
          Die erfassten Daten werden gespeichert, bis Sie uns zur Löschung
          auffordern bzw. das Borlabs-Cookie selbst löschen oder der Zweck für
          die Datenspeicherung entfällt. Zwingende gesetzliche
          Aufbewahrungsfristen bleiben unberührt. Details zur Datenverarbeitung
          von Borlabs Cookie finden Sie unter:{' '}
          <a
            href="https://de.borlabs.io/kb/welche-daten-speichert-borlabs-cookie/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://de.borlabs.io/kb/welche-daten-speichert-borlabs-cookie/
          </a>
          .
        </p>
        <p>
          Der Einsatz der Borlabs-Cookie-Consent-Technologie erfolgt, um die
          gesetzlich vorgeschriebenen Einwilligungen für den Einsatz von Cookies
          einzuholen. Rechtsgrundlage hierfür ist Art. 6 Abs. 1 lit. c DSGVO.
        </p>
        <H3>Server-Log-Dateien</H3>
        <p>
          Der Provider der Seiten erhebt und speichert automatisch Informationen
          in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns
          übermittelt. Dies sind:
        </p>
        <ul>
          <li>Browsertyp und Browserversion</li>
          <li>verwendetes Betriebssystem</li> <li>Referrer URL</li>
          <li>Hostname des zugreifenden Rechners</li>
          <li>Uhrzeit der Serveranfrage</li> <li>IP-Adresse</li>
        </ul>
        <p>
          Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht
          vorgenommen.
        </p>
        <p>
          Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
          lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an
          der technisch fehlerfreien Darstellung und der Optimierung seiner
          Website – hierzu müssen die Server-Log-Files erfasst werden.
        </p>
        <H3>Kontaktformular</H3>
        <p>
          Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre
          Angaben aus dem Anfrageformular inklusive der von Ihnen dort
          angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den
          Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir
          nicht ohne Ihre Einwilligung weiter.
        </p>
        <p>
          Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
          lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
          zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
          erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf
          unserem berechtigten Interesse an der effektiven Bearbeitung der an
          uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
          Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt
          wurde.
        </p>
        <p>
          Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei
          uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
          Speicherung widerrufen oder der Zweck für die Datenspeicherung
          entfällt (z. B. nach abgeschlossener Bearbeitung Ihrer Anfrage).
          Zwingende gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen
          – bleiben unberührt.
        </p>
        <H3>Anfrage per E-Mail, Telefon oder Telefax</H3>
        <p>
          Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre
          Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten
          (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns
          gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre
          Einwilligung weiter.
        </p>
        <p>
          Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
          lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
          zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
          erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf
          unserem berechtigten Interesse an der effektiven Bearbeitung der an
          uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
          Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt
          wurde.
        </p>
        <p>
          Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben
          bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
          Speicherung widerrufen oder der Zweck für die Datenspeicherung
          entfällt (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens).
          Zwingende gesetzliche Bestimmungen – insbesondere gesetzliche
          Aufbewahrungsfristen – bleiben unberührt.
        </p>
        <H3>Kommunikation via WhatsApp</H3>
        <p>
          Für die Kommunikation mit unseren Kunden und sonstigen Dritten nutzen
          wir unter anderem den Instant-Messaging-Dienst WhatsApp. Anbieter ist
          die WhatsApp Ireland Limited, 4 Grand Canal Square, Grand Canal
          Harbour, Dublin 2, Irland.
        </p>
        <p>
          Die Kommunikation erfolgt über eine Ende-zu-Ende-Verschlüsselung
          (Peer-to-Peer), die verhindert, dass WhatsApp oder sonstige Dritte
          Zugriff auf die Kommunikationsinhalte erlangen können. WhatsApp erhält
          jedoch Zugriff auf Metadaten, die im Zuge des Kommunikationsvorgangs
          entstehen (z. B. Absender, Empfänger und Zeitpunkt). Wir weisen ferner
          darauf hin, dass WhatsApp nach eigener Aussage, personenbezogene Daten
          seiner Nutzer mit seiner in den USA ansässigen Konzernmutter Facebook
          teilt. Weitere Details zur Datenverarbeitung finden Sie in der
          Datenschutzrichtlinie von WhatsApp unter:{' '}
          <a
            href="https://www.whatsapp.com/legal/#privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.whatsapp.com/legal/#privacy-policy
          </a>
          .
        </p>
        <p>
          Der Einsatz von WhatsApp erfolgt auf Grundlage unseres berechtigten
          Interesses an einer möglichst schnellen und effektiven Kommunikation
          mit Kunden, Interessenten und sonstigen Geschäfts- und
          Vertragspartnern (Art. 6 Abs. 1 lit. f DSGVO). Sofern eine
          entsprechende Einwilligung abgefragt wurde, erfolgt die
          Datenverarbeitung ausschließlich auf Grundlage der Einwilligung; diese
          ist jederzeit mit Wirkung für die Zukunft widerrufbar.
        </p>
        <p>
          Die zwischen und auf WhatsApp ausgetauschten Kommunikationsinhalte
          verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre
          Einwilligung zur Speicherung widerrufen oder der Zweck für die
          Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung
          Ihrer Anfrage). Zwingende gesetzliche Bestimmungen – insbesondere
          Aufbewahrungsfristen – bleiben unberührt.
        </p>
        <p>
          Wir haben unsere WhatsApp-Accounts so eingestellt, dass es keinen
          automatischen Datenabgleich mit dem Adressbuch auf den im Einsatz
          befindlichen Smartphones macht.
        </p>
        <p>
          Wir haben einen Vertrag über Auftragsverarbeitung (AVV) mit dem oben
          genannten Anbieter geschlossen.
        </p>
        <H3>ProvenExpert</H3>
        <p>
          Wir haben Bewertungssiegel von ProvenExpert auf dieser Website
          eingebunden. Anbieter ist Expert Systems AG, Quedlinburger Str. 1,
          10589 Berlin,{' '}
          <a
            href="https://www.provenexpert.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.provenexpert.com
          </a>
          .
        </p>
        <p>
          Das ProvenExpert-Siegel ermöglicht es uns, Kundenbewertungen, die bei
          ProvenExpert zu unserem Unternehmen abgegeben wurden, auf unserer
          Website in einem Siegel darzustellen. Wenn Sie unsere Website
          besuchen, wird eine Verbindung mit ProvenExpert hergestellt, sodass
          ProvenExpert feststellen kann, dass Sie unsere Website besucht haben.
          Ferner erfasst ProvenExpert Ihre Spracheinstellungen, um das Siegel in
          der gewählten Landessprache anzuzeigen.
        </p>
        <p>
          Die Verwendung von ProvenExpert erfolgt auf Grundlage von Art. 6 Abs.
          1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an
          einer möglichst nachvollziehbaren Darstellung von Kundenbewertungen.
          Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die
          Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a
          DSGVO; die Einwilligung ist jederzeit widerrufbar.
        </p>
        <H2>5. Analyse-Tools und Werbung</H2>
        <H3>Google Tag Manager</H3>
        <p>
          Wir setzen den Google Tag Manager ein. Anbieter ist die Google Ireland
          Limited, Gordon House, Barrow Street, Dublin 4, Irland.
        </p>
        <p>
          Der Google Tag Manager ist ein Tool, mit dessen Hilfe wir Tracking-
          oder Statistik-Tools und andere Technologien auf unserer Website
          einbinden können. Der Google Tag Manager selbst erstellt keine
          Nutzerprofile, speichert keine Cookies und nimmt keine eigenständigen
          Analysen vor. Er dient lediglich der Verwaltung und Ausspielung der
          über ihn eingebundenen Tools. Der Google Tag Manager erfasst jedoch
          Ihre IP-Adresse, die auch an das Mutterunternehmen von Google in die
          Vereinigten Staaten übertragen werden kann.
        </p>
        <p>
          Der Einsatz des Google Tag Managers erfolgt auf Grundlage von Art. 6
          Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
          Interesse an einer schnellen und unkomplizierten Einbindung und
          Verwaltung verschiedener Tools auf seiner Website. Sofern eine
          entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
          ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die
          Einwilligung ist jederzeit widerrufbar.
        </p>
        <H3>Google Analytics</H3>
        <p>
          Diese Website nutzt Funktionen des Webanalysedienstes Google
          Analytics. Anbieter ist die Google Ireland Limited („Google“), Gordon
          House, Barrow Street, Dublin 4, Irland.
        </p>
        <p>
          Google Analytics ermöglicht es dem Websitebetreiber, das Verhalten der
          Websitebesucher zu analysieren. Hierbei erhält der Websitebetreiber
          verschiedene Nutzungsdaten, wie z. B. Seitenaufrufe, Verweildauer,
          verwendete Betriebssysteme und Herkunft des Nutzers. Diese Daten
          werden von Google ggf. in einem Profil zusammengefasst, das dem
          jeweiligen Nutzer bzw. dessen Endgerät zugeordnet ist.
        </p>
        <p>
          Des Weiteren können wir Google Analytics u. a. Ihre Maus- und
          Scrollbewegungen und Klicks aufzeichnen. Ferner verwendet Google
          Analytics verschiedene Modellierungsansätze, um die erfassten
          Datensätze zu ergänzen und setzt Machine Learning-Technologien bei der
          Datenanalyse ein.
        </p>
        <p>
          Google Analytics verwendet Technologien, die die Wiedererkennung des
          Nutzers zum Zwecke der Analyse des Nutzerverhaltens ermöglichen (z. B.
          Cookies oder Device-Fingerprinting). Die von Google erfassten
          Informationen über die Benutzung dieser Website werden in der Regel an
          einen Server von Google in den USA übertragen und dort gespeichert.
        </p>
        <p>
          Die Nutzung dieses Analyse-Tools erfolgt auf Grundlage von Art. 6 Abs.
          1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an
          der Analyse des Nutzerverhaltens, um sowohl sein Webangebot als auch
          seine Werbung zu optimieren. Sofern eine entsprechende Einwilligung
          abgefragt wurde (z. B. eine Einwilligung zur Speicherung von Cookies),
          erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs.
          1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.
        </p>
        <p>
          Die Datenübertragung in die USA wird auf die Standardvertragsklauseln
          der EU-Kommission gestützt. Details finden Sie hier:{' '}
          <a
            href="https://privacy.google.com/businesses/controllerterms/mccs/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://privacy.google.com/businesses/controllerterms/mccs/
          </a>
          .
        </p>
        <H3>IP Anonymisierung</H3>
        <p>
          Wir haben auf dieser Website die Funktion IP-Anonymisierung aktiviert.
          Dadurch wird Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten
          der Europäischen Union oder in anderen Vertragsstaaten des Abkommens
          über den Europäischen Wirtschaftsraum vor der Übermittlung in die USA
          gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen
          Server von Google in den USA übertragen und dort gekürzt. Im Auftrag
          des Betreibers dieser Website wird Google diese Informationen
          benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die
          Websiteaktivitäten zusammenzustellen und um weitere mit der
          Websitenutzung und der Internetnutzung verbundene Dienstleistungen
          gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google
          Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit
          anderen Daten von Google zusammengeführt.
        </p>
        <H4>Browser Plugin</H4>{' '}
        <p>
          Sie können die Erfassung und Verarbeitung Ihrer Daten durch Google
          verhindern, indem Sie das unter dem folgenden Link verfügbare
          Browser-Plugin herunterladen und installieren:{' '}
          <a
            href="https://tools.google.com/dlpage/gaoptout?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://tools.google.com/dlpage/gaoptout?hl=de
          </a>
          .
        </p>
        <p>
          Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics
          finden Sie in der Datenschutzerklärung von Google:{' '}
          <a
            href="https://support.google.com/analytics/answer/6004245?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://support.google.com/analytics/answer/6004245?hl=de
          </a>
          .
        </p>
        <H3>Auftragsverarbeitung</H3>
        <p>
          Wir haben mit Google einen Vertrag zur Auftragsverarbeitung
          abgeschlossen und setzen die strengen Vorgaben der deutschen
          Datenschutzbehörden bei der Nutzung von Google Analytics vollständig
          um.
        </p>
        <H3>Demografische Merkmale bei Google Analytics</H3>
        <p>
          Diese Website nutzt die Funktion „demografische Merkmale“ von Google
          Analytics, um den Websitebesuchern passende Werbeanzeigen innerhalb
          des Google-Werbenetzwerks anzeigen zu können. Dadurch können Berichte
          erstellt werden, die Aussagen zu Alter, Geschlecht und Interessen der
          Seitenbesucher enthalten. Diese Daten stammen aus interessenbezogener
          Werbung von Google sowie aus Besucherdaten von Drittanbietern. Diese
          Daten können keiner bestimmten Person zugeordnet werden. Sie können
          diese Funktion jederzeit über die Anzeigeneinstellungen in Ihrem
          Google-Konto deaktivieren oder die Erfassung Ihrer Daten durch Google
          Analytics wie im Punkt „Widerspruch gegen Datenerfassung“ dargestellt
          generell untersagen.
        </p>
        <H4>Speicherdauer</H4>
        <p>
          Bei Google gespeicherte Daten auf Nutzer- und Ereignisebene, die mit
          Cookies, Nutzerkennungen (z. B. User ID) oder Werbe-IDs (z. B.
          DoubleClick-Cookies, Android-Werbe-ID) verknüpft sind, werden nach 2
          Monaten anonymisiert bzw. gelöscht. Details hierzu ersehen Sie unter
          folgendem Link:{' '}
          <a
            href="https://support.google.com/analytics/answer/7667196?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://support.google.com/analytics/answer/7667196?hl=de
          </a>
        </p>
        <H3>Google Ads</H3>
        <p>
          Der Websitebetreiber verwendet Google Ads. Google Ads ist ein
          Online-Werbeprogramm der Google Ireland Limited („Google“), Gordon
          House, Barrow Street, Dublin 4, Irland.
        </p>
        <p>
          Google Ads ermöglicht es uns Werbeanzeigen in der Google-Suchmaschine
          oder auf Drittwebseiten auszuspielen, wenn der Nutzer bestimmte
          Suchbegriffe bei Google eingibt (Keyword-Targeting). Ferner können
          zielgerichtete Werbeanzeigen anhand der bei Google vorhandenen
          Nutzerdaten (z. B. Standortdaten und Interessen) ausgespielt werden
          (Zielgruppen-Targeting). Wir als Websitebetreiber können diese Daten
          quantitativ auswerten, indem wir beispielsweise analysieren, welche
          Suchbegriffe zur Ausspielung unserer Werbeanzeigen geführt haben und
          wie viele Anzeigen zu entsprechenden Klicks geführt haben.
        </p>
        <p>
          Die Nutzung von Google Ads erfolgt auf Grundlage von Art. 6 Abs. 1
          lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an
          einer möglichst effektiven Vermarktung seiner Dienstleistung Produkte.
        </p>
        <p>
          Die Datenübertragung in die USA wird auf die Standardvertragsklauseln
          der EU-Kommission gestützt. Details finden Sie hier:{' '}
          <a
            href="https://policies.google.com/privacy/frameworks"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://policies.google.com/privacy/frameworks
          </a>
          und{' '}
          <a
            href="https://privacy.google.com/businesses/controllerterms/mccs/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://privacy.google.com/businesses/controllerterms/mccs/
          </a>
          .
        </p>
        <H3>Google Remarketing</H3>
        <p>
          Diese Website nutzt die Funktionen von Google Analytics Remarketing.
          Anbieter ist die Google Ireland Limited („Google“), Gordon House,
          Barrow Street, Dublin 4, Irland.
        </p>
        <p>
          Google Remarketing analysiert Ihr Nutzerverhalten auf unserer Website
          (z. B. Klick auf bestimmte Produkte), um Sie in bestimmte
          Werbe-Zielgruppen einzuordnen und Ihnen anschließend beim Besuch von
          anderen Onlineangeboten passende Webebotschaften auszuspielen
          (Remarketing bzw. Retargeting).
        </p>
        <p>
          Des Weiteren können die mit Google Remarketing erstellten
          Werbe-Zielgruppen mit den geräteübergreifenden Funktionen von Google
          verknüpft werden. Auf diese Weise können interessenbezogene,
          personalisierte Werbebotschaften, die in Abhängigkeit Ihres früheren
          Nutzungs- und Surfverhaltens auf einem Endgerät (z. B. Handy) an Sie
          angepasst wurden auch auf einem anderen Ihrer Endgeräte (z. B. Tablet
          oder PC) angezeigt werden.
        </p>
        <p>
          Wenn Sie über einen Google-Account verfügen, können Sie der
          personalisierten Werbung unter folgendem Link widersprechen:{' '}
          <a
            href="https://www.google.com/settings/ads/onweb/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.google.com/settings/ads/onweb/
          </a>
          .
        </p>
        <p>
          Die Nutzung von Google Remarketing erfolgt auf Grundlage von Art. 6
          Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
          Interesse an einer möglichst effektiven Vermarktung seiner Produkte.
          Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die
          Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a
          DSGVO; die Einwilligung ist jederzeit widerrufbar.
        </p>
        <p>
          Weitergehende Informationen und die Datenschutzbestimmungen finden Sie
          in der Datenschutzerklärung von Google unter:{' '}
          <a
            href="https://policies.google.com/technologies/ads?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://policies.google.com/technologies/ads?hl=de
          </a>
          .
        </p>
        <H4>Zielgruppenbildung mit Kundenabgleich</H4>
        <p>
          Zur Zielgruppenbildung verwenden wir unter anderem den Kundenabgleich
          von Google Remarketing. Hierbei übergeben wir bestimmte Kundendaten
          (z. B. E-Mail-Adressen) aus unseren Kundenlisten an Google. Sind die
          betreffenden Kunden Google-Nutzer und in ihrem Google-Konto
          eingeloggt, werden ihnen passende Werbebotschaften innerhalb des
          Google-Netzwerks (z. B. bei YouTube, Gmail oder in der Suchmaschine)
          angezeigt.
        </p>
        <H2>Google Conversion-Tracking</H2>
        <p>
          Diese Website nutzt Google Conversion Tracking. Anbieter ist die
          Google Ireland Limited („Google“), Gordon House, Barrow Street, Dublin
          4, Irland.
        </p>
        <p>
          Mit Hilfe von Google-Conversion-Tracking können Google und wir
          erkennen, ob der Nutzer bestimmte Aktionen durchgeführt hat. So können
          wir beispielsweise auswerten, welche Buttons auf unserer Website wie
          häufig geklickt und welche Produkte besonders häufig angesehen oder
          gekauft wurden. Diese Informationen dienen dazu,
          Conversion-Statistiken zu erstellen. Wir erfahren die Gesamtanzahl der
          Nutzer, die auf unsere Anzeigen geklickt haben und welche Aktionen sie
          durchgeführt haben. Wir erhalten keine Informationen, mit denen wir
          den Nutzer persönlich identifizieren können. Google selbst nutzt zur
          Identifikation Cookies oder vergleichbare
          Wiedererkennungstechnologien.
        </p>
        <p>
          Die Nutzung von Google Conversion-Tracking erfolgt auf Grundlage von
          Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
          Interesse an der Analyse des Nutzerverhaltens, um sowohl sein
          Webangebot als auch seine Werbung zu optimieren. Sofern eine
          entsprechende Einwilligung abgefragt wurde (z. B. eine Einwilligung
          zur Speicherung von Cookies), erfolgt die Verarbeitung ausschließlich
          auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist
          jederzeit widerrufbar.
        </p>
        <p>
          Mehr Informationen zu Google Conversion-Tracking finden Sie in den
          Datenschutzbestimmungen von Google:{' '}
          <a
            href="https://policies.google.com/privacy?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://policies.google.com/privacy?hl=de
          </a>
          .
        </p>
        <H2>6. Newsletter</H2>
        <H3>Newsletter­daten</H3>
        <p>
          Wenn Sie den auf der Website angebotenen Newsletter beziehen möchten,
          benötigen wir von Ihnen eine E-Mail-Adresse sowie Informationen,
          welche uns die Überprüfung gestatten, dass Sie der Inhaber der
          angegebenen E-Mail-Adresse und mit dem Empfang des Newsletters
          einverstanden sind. Weitere Daten werden nicht bzw. nur auf
          freiwilliger Basis erhoben. Für die Abwicklung der Newsletter nutzen
          wir Newsletterdiensteanbieter, die nachfolgend beschrieben werden.
        </p>
        <H3>CleverReach</H3>
        <p>
          Diese Website nutzt CleverReach für den Versand von Newslettern.
          Anbieter ist die CleverReach GmbH & Co. KG, Schafjückenweg 2, 26180
          Rastede, Deutschland (nachfolgend: „CleverReach“). CleverReach ist ein
          Dienst, mit dem der Newsletterversand organisiert und analysiert
          werden kann. Die von Ihnen zwecks Newsletterbezug eingegebenen Daten
          (z. B. E-Mail-Adresse) werden auf den Servern von CleverReach in
          Deutschland bzw. Irland gespeichert.
        </p>
        <p>
          Unsere mit CleverReach versandten Newsletter ermöglichen uns die
          Analyse des Verhaltens der Newsletterempfänger. Hierbei kann u. a.
          analysiert werden, wie viele Empfänger die Newsletternachricht
          geöffnet haben und wie oft welcher Link im Newsletter angeklickt
          wurde. Mit Hilfe des sogenannten Conversion-Trackings kann außerdem
          analysiert werden, ob nach Anklicken des Links im Newsletter eine
          vorab definierte Aktion (z. B. Kauf eines Produkts auf dieser Website)
          erfolgt ist. Weitere Informationen zur Datenanalyse durch
          CleverReach-Newsletter erhalten Sie unter:{' '}
          <a
            href="https://www.cleverreach.com/de/funktionen/reporting-und-tracking/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.cleverreach.com/de/funktionen/reporting-und-tracking/
          </a>
          .
        </p>
        <p>
          Die Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung (Art. 6
          Abs. 1 lit. a DSGVO). Sie können diese Einwilligung jederzeit
          widerrufen, indem Sie den Newsletter abbestellen. Die Rechtmäßigkeit
          der bereits erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf
          unberührt.
        </p>
        <p>
          Wenn Sie keine Analyse durch CleverReach wollen, müssen Sie den
          Newsletter abbestellen. Hierfür stellen wir in jeder
          Newsletternachricht einen entsprechenden Link zur Verfügung.
        </p>
        <p>
          Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten
          Daten werden von uns bis zu Ihrer Austragung aus dem Newsletter bei
          uns bzw. dem Newsletterdiensteanbieter gespeichert und nach der
          Abbestellung des Newsletters aus der Newsletterverteilerliste
          gelöscht. Daten, die zu anderen Zwecken bei uns gespeichert wurden,
          bleiben hiervon unberührt.
        </p>
        <p>
          Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre
          E-Mail-Adresse bei uns bzw. dem Newsletterdiensteanbieter ggf. in
          einer Blacklist gespeichert, um künftige Mailings zu verhindern. Die
          Daten aus der Blacklist werden nur für diesen Zweck verwendet und
          nicht mit anderen Daten zusammengeführt. Dies dient sowohl Ihrem
          Interesse als auch unserem Interesse an der Einhaltung der
          gesetzlichen Vorgaben beim Versand von Newslettern (berechtigtes
          Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO). Die Speicherung in
          der Blacklist ist zeitlich nicht befristet.
          <strong>
            Sie können der Speicherung widersprechen, sofern Ihre Interessen
            unser berechtigtes Interesse überwiegen.
          </strong>
        </p>
        <p>
          Näheres entnehmen Sie den Datenschutzbestimmungen von CleverReach
          unter:{' '}
          <a
            href="https://www.cleverreach.com/de/datenschutz/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.cleverreach.com/de/datenschutz/
          </a>
          .
        </p>
        <H3>Auftragsverarbeitung</H3>
        <p>
          Wir haben einen Vertrag über Auftragsverarbeitung (AVV) mit dem oben
          genannten Anbieter geschlossen. Hierbei handelt es sich um einen
          datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet, dass
          dieser die personenbezogenen Daten unserer Websitebesucher nur nach
          unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.
        </p>
        <H2>7. Plugins und Tools</H2>
        <H3>YouTube mit erweitertem Datenschutz</H3>
        <p>
          Diese Website bindet Videos der YouTube ein. Betreiber der Seiten ist
          die Google Ireland Limited („Google“), Gordon House, Barrow Street,
          Dublin 4, Irland.
        </p>
        <p>
          Wir nutzen YouTube im erweiterten Datenschutzmodus. Dieser Modus
          bewirkt laut YouTube, dass YouTube keine Informationen über die
          Besucher auf dieser Website speichert, bevor diese sich das Video
          ansehen. Die Weitergabe von Daten an YouTube-Partner wird durch den
          erweiterten Datenschutzmodus hingegen nicht zwingend ausgeschlossen.
          So stellt YouTube – unabhängig davon, ob Sie sich ein Video ansehen –
          eine Verbindung zum Google DoubleClick-Netzwerk her.
        </p>
        <p>
          Sobald Sie ein YouTube-Video auf dieser Website starten, wird eine
          Verbindung zu den Servern von YouTube hergestellt. Dabei wird dem
          YouTube-Server mitgeteilt, welche unserer Seiten Sie besucht haben.
          Wenn Sie in Ihrem YouTube-Account eingeloggt sind, ermöglichen Sie
          YouTube, Ihr Surfverhalten direkt Ihrem persönlichen Profil
          zuzuordnen. Dies können Sie verhindern, indem Sie sich aus Ihrem
          YouTube-Account ausloggen.
        </p>
        <p>
          Des Weiteren kann YouTube nach Starten eines Videos verschiedene
          Cookies auf Ihrem Endgerät speichern oder vergleichbare
          Wiedererkennungstechnologien (z. B. Device-Fingerprinting) einsetzen.
          Auf diese Weise kann YouTube Informationen über Besucher dieser
          Website erhalten. Diese Informationen werden u. a. verwendet, um
          Videostatistiken zu erfassen, die Anwenderfreundlichkeit zu verbessern
          und Betrugsversuchen vorzubeugen.
        </p>
        <p>
          Gegebenenfalls können nach dem Start eines YouTube-Videos weitere
          Datenverarbeitungsvorgänge ausgelöst werden, auf die wir keinen
          Einfluss haben.
        </p>
        <p>
          Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden
          Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes
          Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine
          entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
          ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die
          Einwilligung ist jederzeit widerrufbar.
        </p>
        <p>
          Weitere Informationen über Datenschutz bei YouTube finden Sie in deren
          Datenschutzerklärung unter:{' '}
          <a
            href="https://policies.google.com/privacy?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://policies.google.com/privacy?hl=de
          </a>
          .
        </p>
        <H3>Google Web Fonts (lokales Hosting)</H3>
        <p>
          Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so
          genannte Web Fonts, die von Google bereitgestellt werden. Die Google
          Fonts sind lokal installiert. Eine Verbindung zu Servern von Google
          findet dabei nicht statt.
        </p>{' '}
        <p>
          Weitere Informationen zu Google Web Fonts finden Sie unter{' '}
          <a
            href="https://developers.google.com/fonts/faq"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://developers.google.com/fonts/faq
          </a>{' '}
          und in der Datenschutzerklärung von Google:{' '}
          <a
            href="https://policies.google.com/privacy?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://policies.google.com/privacy?hl=de
          </a>
          .
        </p>
        <H3>Google reCAPTCHA</H3>
        <p>
          Wir nutzen „Google reCAPTCHA“ (im Folgenden „reCAPTCHA“) auf dieser
          Website. Anbieter ist die Google Ireland Limited („Google“), Gordon
          House, Barrow Street, Dublin 4, Irland.
        </p>{' '}
        <p>
          Mit reCAPTCHA soll überprüft werden, ob die Dateneingabe auf dieser
          Website (z. B. in einem Kontaktformular) durch einen Menschen oder
          durch ein automatisiertes Programm erfolgt. Hierzu analysiert
          reCAPTCHA das Verhalten des Websitebesuchers anhand verschiedener
          Merkmale. Diese Analyse beginnt automatisch, sobald der
          Websitebesucher die Website betritt. Zur Analyse wertet reCAPTCHA
          verschiedene Informationen aus (z. B. IP-Adresse, Verweildauer des
          Websitebesuchers auf der Website oder vom Nutzer getätigte
          Mausbewegungen). Die bei der Analyse erfassten Daten werden an Google
          weitergeleitet.
        </p>{' '}
        <p>
          Die reCAPTCHA-Analysen laufen vollständig im Hintergrund.
          Websitebesucher werden nicht darauf hingewiesen, dass eine Analyse
          stattfindet.
        </p>{' '}
        <p>
          Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art. 6
          Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
          Interesse daran, seine Webangebote vor missbräuchlicher
          automatisierter Ausspähung und vor SPAM zu schützen. Sofern eine
          entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
          ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die
          Einwilligung ist jederzeit widerrufbar.
        </p>{' '}
        <p>
          Weitere Informationen zu Google reCAPTCHA entnehmen Sie den
          Google-Datenschutzbestimmungen und den Google Nutzungsbedingungen
          unter folgenden Links:{' '}
          <a
            href="https://policies.google.com/privacy?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://policies.google.com/privacy?hl=de
          </a>{' '}
          und{' '}
          <a
            href="https://policies.google.com/terms?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://policies.google.com/terms?hl=de
          </a>
          .
        </p>
        <H2>8. eCommerce und Zahlungs­anbieter</H2>
        <H3>Verarbeiten von Daten (Kunden- und Vertragsdaten)</H3>
        <p>
          Wir erheben, verarbeiten und nutzen personenbezogene Daten nur, soweit
          sie für die Begründung, inhaltliche Ausgestaltung oder Änderung des
          Rechtsverhältnisses erforderlich sind (Bestandsdaten). Dies erfolgt
          auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von
          Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen
          gestattet. Personenbezogene Daten über die Inanspruchnahme dieser
          Website (Nutzungsdaten) erheben, verarbeiten und nutzen wir nur,
          soweit dies erforderlich ist, um dem Nutzer die Inanspruchnahme des
          Dienstes zu ermöglichen oder abzurechnen.
        </p>{' '}
        <p>
          Die erhobenen Kundendaten werden nach Abschluss des Auftrags oder
          Beendigung der Geschäftsbeziehung gelöscht. Gesetzliche
          Aufbewahrungsfristen bleiben unberührt.
        </p>
      </StyledFlex>
    </Layout>
  );
}

export default Datenschutz;

export const datenschutzQuery = graphql`
  query DatenschutzQuery {
    allPrismicLanding {
      edges {
        node {
          data {
            bgcolor1
            captioncolor
            body {
              ... on PrismicLandingDataBodyButtonOnHero {
                id
                slice_type
                items {
                  button_color
                  hover_color
                  text_color
                }
              }
            }
          }
        }
      }
    }
  }
`;
